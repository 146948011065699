@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,400;1,600&family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,400;1,600&family=Lato:wght@300&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,400;1,600&family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@600&family=EB+Garamond:ital,wght@1,600&family=Indie+Flower&family=Sriracha&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baskervville&family=Cinzel:wght@600&family=EB+Garamond:ital,wght@1,600&family=Indie+Flower&family=Sriracha&display=swap');

/* ABOUT ME STYLES */
.abootme{
  background-color: wheat;
  text-align: center;
  padding: 0 1vw 0 1vw ;
  background-size: cover;
  overflow: auto;
  font-family: 'Sriracha', cursive;
  
}
.abootme h1{
  text-align: center;
}
.abootme img{
  height: 15%;
  width: 35%;
}

.picturas img{
  height: 25%;
  width: 25%;
  border: 2px solid black;
  border-radius: 5%;
}

.infoo body{
  /* margin: 1rem 5rem 0 5rem ; */
  text-indent: 3%;
  font-size: x-large;
}

.info2 body{
  /* margin: 1rem 5rem 0 5rem ; */
  text-indent: 3%;
  font-size: x-large;
  /* font-weight: 600; */
}
.infoo a{
  color: green;
  font-weight: 800;
  text-decoration:none;
}

.end a1{
  font-size: x-large;
}
.end a2{
    font-size: x-large;
 
}

/* PROJECTS STYLES  */
.projects{
  background-color: thistle;
}


/* Fun Styles */
.funtime{
  background-color:rgba(127, 255, 212, 0.582);
  text-align: center;
}
.funtime img{
  height: 15%;
  width: 35%;
}
.funtime h2{
  text-align: center;
}
.funtime body{
  text-align: center;
  margin: 1rem 5rem 0 5rem ;
  font-family:serif; 
  font-size: x-large;
}
.row h3{
  text-align: center;
}

.column {
  float: left;
  width: 50%;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}


/* HOME STYLES */
.Home{
  background-color: rgba(245, 142, 105, 0.788);
  text-align: center; 
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: auto;
  padding: 0 5vw 0 5vw ;
}
.Home img{
  height: 10%;
  width: 25%;
  border-radius: 50%;
  border: 4px solid black;
}
.Home a1{
  text-align:center;
  font-size: 200%;
  font-family: 'EB Garamond', serif;
  
}
.Home a2{
  text-align: center;
  font-size: large;
  font-family: 'Sriracha', cursive;
  font-weight: 400;

}

/* ******************* ***/
/****** RESTART CSS ******/
/* ********************* */

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
}
